@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  color: #111924;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  overflow-x: hidden;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}


/* Overriding styles for react-textarea-autocomplete */
.rta {
  position: relative;
}

.rta__autocomplete {
  position: absolute;
  z-index: 2;
  margin-top: var(--autocomplete-margin-top, 0);
}

.rta__list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  animation-name: rtaEnter;
  animation-duration: .1s;
}

@keyframes rtaEnter {
  from {
    opacity: 0;
    transform: translateY(6px) scale(0.98);
  }

  to {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.rta__entity {
  outline: none;
}